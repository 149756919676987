<template>
	<div class = "add-friend-import">
		<div class="normal-dialog">
			<el-dialog title="选择员工" :visible="visible" width="900px" @close="closestaff">
				<div class="list-box">
					<div class="list-left">
						<div class="flex0">
							<el-input placeholder="员工姓名、手机号"  @input="treeSearch" v-model="keywords" style="width: 250px;margin-bottom: 10px;margin-right: 10px;"></el-input>
							<!-- <el-button type="primary" @click="treeSearch" size="mini">搜索</el-button> -->
						</div>
						<div class="flex1" v-show="!keywords">
							<el-tree class = "positiontree" ref="tree" :data="list" node-key="Id" :props="options" :default-expanded-keys="defaultKey" show-checkbox
							@check = "checkthebox">
								<span class="custom-tree-node" slot-scope="{ node, data }">
									<div class = "flexRow" style="color: #333;" v-if="data.Type !== 3">
										<img src="@/assets/img/folder-fill@2x.png" style="width:18px;height:18px;margin-right:5px" alt="">
										{{ node.label }}
										({{data.EmployeeCount}})
									</div>
									<span style="color: #333;font-size: 13px;" v-else>
										<img v-if="data.HeadUrl" class="hd" :src="data.HeadUrl"/>
										<span class="hd-normal" v-else>员</span>
										{{ node.label }}
										({{data.Phone}})
									</span>
									<!-- handleStop就是为了阻止冒泡， 没其它用途 -->
									<!-- <span @click.stop="handleStop">
										<el-checkbox  :indeterminate="data.isIndeterminate" v-model="data.checked" @change="(e)=>{checkChange(e, data)}"></el-checkbox>
									</span> -->
								</span>
							</el-tree>
						</div>
						<div class="flex1" v-show="keywords">
							<div class="select-list" v-if="searchList.length">
								<div class="select-item select-item1" style="margin-bottom: 10px;" v-for="(item, index) in searchList" :key="item.Id">
									<span style="color: #333;font-size: 13px;">
										<img v-if="item.HeadUrl" class="hd" :src="item.HeadUrl" />
										<span class="hd-normal" v-else>员</span>
										{{ item.Name }}
										({{item.Phone}})
									</span>
									<span @click.stop="handleStop">
										<el-checkbox v-model="item.checked" @change="(e)=>{checkChange(e, item)}"></el-checkbox>
									</span>
								</div>
							</div>
							<div v-else style="text-align: center;font-size: 13px;color: #999;padding-top: 100px;">搜索无结果，请确认输入的名字或手机号是否正确</div>
						</div>
					</div>
					<div class="list-right">
						<div class="title">已选择的接待人员</div>
						<div class="select-list">
							<div class="select-item" v-for="(item, index) in selectedData" :key="item.Id">
								<span style="color: #333;" v-if="item.Type !== 3">
									<i class="el-icon-s-shop" style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i>
									{{ item.Name }}
								</span>
								<span style="color: #333;font-size: 13px;" v-else>
									<img v-if="item.HeadUrl" class="hd" :src="item.HeadUrl" />
									<span class="hd-normal" v-else>员</span>
									{{ item.Name }}
									({{item.Phone}})
								</span>
								<i class="el-icon-close delete-btn" @click="handleDeleteSelect(index)"></i>
							</div>
						</div>
					</div>
				</div>
				
				<span slot="footer" class="dialog-footer">
					<el-button @click="closestaff">取 消</el-button>
					<el-button type="primary" @click="handleSureSelect">确 定</el-button>
				</span>
				
			</el-dialog>
		</div>
	</div>
</template>
<script>
	import config from '@/config';
	import {
		employeechooseEmployeeByRole,
	} from '@/api/sv3.0.0.js';
	

	export default {
		components: {
		},
		props: {
			visible: {
				type: Boolean,
				default: false
			},
			sellist:{
				type:Array,
				default:()=>{
					return []
				}
			}
		},
		created () {
			if(this.$route.params.TagDetailId){
				this.activeName = 'tag'
				this.taglist = []
				let data = JSON.parse(JSON.stringify(this.$route.params))
				data.TagDetailId = Number(data.TagDetailId)
				this.taglist.push(data)
			}
		},
		data() {
			return {
				options: {
					children: 'NextList',
					label: 'Name'
				},
				uploadUrl: config.UPLOAD_ZIP,
				imgUrl: config.IMG_BASE,
				activeName: 'excel',
				date: '',
				selectList: [],
				searchList: [],
				
				exportLoading: false,
				employeeVisible: false,
				list: [],
				defaultKey: [],
				selectedData: [],
				
				keywords: '',
				
				fileName: '',
				fileUrl: '',
				fansFirst: false,
				
				ShopId: null,

				dialogTag:false,
				importVisible:false,
				taglist:[],
				isopen:false,
			}
		},
		watch: {
			visible() {
				if (this.visible) {
					this.selectedData = this.sellist
					this.getList();
				}
			}
		},
		beforeMount() {
			this.getList()
		},
		methods: {
			checkChange(e,item){
				// console.log(e,item)
				let msg = JSON.parse(JSON.stringify(item))
				if(e){
					this.selectedData.push(msg)
				}else{
					this.selectedData = this.selectedData.filter((v)=>{
						return v.Id!=msg.Id
					})
				}
				this.settreecheck()
			},
			checkthebox(e){
				// console.log(this.$refs.tree.getCheckedNodes())
				this.selectedData = this.$refs.tree.getCheckedNodes().filter((v)=>{
					return v.Type == 3
				})
				
			},
			closestaff(){
				this.$emit('closestaff')
			},
			async getList() {
				try {
					// console.log(111)
					const res = await employeechooseEmployeeByRole();
					var list = [res.Result];
					
					list.map(item=>{
						item.checked = false;
						item.NextList.map(item1=>{
							item1.checked = false;
							
							item1.NextList.map(item2=>{
								item2.checked = false;
								return item2;
							})
							
							return item1;
						})
						
						return item;
					})
					
					this.defaultKey = [-1];
					this.list = list;
					if(this.selectedData&&this.selectedData.length){
						this.settreecheck()
					}
				} catch (e) {
					//TODO handle the exception
				}
			},
			handleUploadSuccess(res){
				this.fileUrl = res[0];
			},
			handleClose() {
				this.$emit('close')
			},
			handleDeleteSelect(index){
				let msg = JSON.parse(JSON.stringify(this.selectedData[index]))
				this.selectedData.splice(index, 1);
				this.searchList = this.searchList.map((v)=>{
					if(v.Id==msg.Id){
						v.checked = false
					}
					return v
				})
				this.settreecheck()
			},
			settreecheck(){
				this.$nextTick(()=>{
					this.$refs.tree.setCheckedKeys([])
					this.$refs.tree.setCheckedNodes(this.selectedData)
				})
			},
			treeCheckChange(){
				var list = JSON.parse(JSON.stringify(this.list));
				var ids = this.selectedData.map(item=>{
					return item.Id
				})
				
				this.list.map(item=>{
					item.checked = ids.indexOf(item.Id) > -1;
					item.checklength = 0
					// item.isIndeterminate = false
					item.NextList.map(item1=>{
						item1.checked = ids.indexOf(item1.Id) > -1;
						item1.checklength = 0
						// item1.isIndeterminate = false
						item1.NextList.map(item2=>{
							item2.checked = ids.indexOf(item2.Id) > -1;
							item2.checklength = 0
							// item2.isIndeterminate = false
							return item2;
						})
						
						return item1;
					})
					
					return item;
				})
				this.treeisIndeterminate()
			},
			treeisIndeterminate(){
				this.list.map(item=>{
					item.isIndeterminate = false
					
					item.NextList.map(item1=>{
						item1.isIndeterminate = false
						if(item1.checked){
							item.checklength++
						}
						
						item1.NextList.map(item2=>{
							item2.isIndeterminate = false
							if(item2.checked){
								item2.checklength++
							}
							return item2
						})
						if(item1.checklength){
							item1.isIndeterminate = true
						}else	if(item1.checklength&&item1.checklength>=item1.NextList.length){
							item1.checked = true
						}
						return item1
					})
					if(item.checklength){
						item.isIndeterminate = true
					}else	if(item.checklength&&item.checklength>=item.NextList.length){
						item.checked = true
					}
					return item
				})
			},
			handleStop(){},
			getsearch(){
				var list = []
				this.list.map(item=>{
					item.NextList.map(item1=>{
						item1.NextList.map(item2=>{
							if (item2.Name.indexOf(this.keywords) > -1 || item2.Phone.indexOf(this.keywords) > -1){
								list.push(item2);
							}
						})
					})
				})
				return list
			},
			treeSearch(){
				let list = []
				if (this.keywords){
					list = this.getsearch()
					// let searchlist = JSON.parse(JSON.stringify(this.$refs.tree.getCheckedNodes()))
					let idlist = []
					//如果右边选中了 获取id的合集
					if(this.selectedData&&this.selectedData.length){
						idlist = this.selectedData.map((v)=>{
							return v.Id
						})
					}
					//如果搜索有结果 获取结果并判断是否已经在右边 如果在默认勾选
					if(list&&list.length){
						list.map((v)=>{
							if(idlist.includes(v.Id)){
								v.checked = true
							}
							return v
						})
					}
					this.searchList = list;
				}else{
					this.treeCheckChange();
				}
			},
			
			handleSureSelect(){

				if (!this.selectedData.length){
					this.$message.error('请选择接待人员');
					return;
				}
				
				this.selectList = JSON.parse(JSON.stringify( this.selectedData));
				this.$emit('confirm',this.selectList)
			},
			handleDeleteEmployee(index){
				this.selectList.splice(index, 1);
				this.selectedData.splice(index, 1);
			},
			showSelectPop(){
				this.employeeVisible = true;
				// this.getList()
				this.treeCheckChange();
			},
			
		}
	}
</script>

<style lang="less">
	.add-friend-import {

		font-size: 14px;

		.import-tips {
			border: 1px solid #409EFF;
			background-color: #FBFDFF;
			padding: 10px;
			line-height: 24px;
			color: #999;
			border-radius: 4px;
		}
		
		.form-title{
			display: inline-block;
			width: 140px;
			text-align: right;
			margin-right: 5px;
		}
		.tagtitle::before{
			content: '*';
			color: #f56c6c;
			margin-right:4px
		}
		
		.hd{
			width: 18px;
			height: 18px;
			border-radius: 2px;
			vertical-align: -3px;
		}
		.hd-normal{
			display: inline-block;
			width: 18px;
			height: 18px;
			line-height: 18px;
			text-align: center;
			font-size: 12px;
			color: #fff;
			border-radius: 2px;
			vertical-align: 1px;
			background-color: #409eff;
		}
		.custom-tree-node{
			flex: 1;
			display: flex;
			justify-content: space-between;
			padding-right: 10px;
		}
		
		.list-box{
			display: flex;
			height: 60vh;
			
			.list-left{
				flex: 0 0 auto;
				width: 50%;
				height: 100%;
				overflow: hidden;
				border-right: 1px solid #ddd;
				box-sizing: border-box;
				
				display: flex;
				flex-direction: column;
				
				.flex0{
					flex: 0 0 auto;
				}
				.flex1{
					flex: 1 1 auto;
					overflow: auto;
				}
			}
			
			.list-right{
				flex: 0 0 auto;
				width: 50%;
				height: 100%;
				overflow: auto;
				padding-left: 20px;
				box-sizing: border-box;
				
				.title{
					margin-bottom: 15px;
				}
				
				.select-list{
					.select-item{
						position: relative;
						margin-bottom: 15px;
					}
					
					.el-icon-close{
						position: absolute;
						right: 20px;
						top: 0px;
						font-size: 18px;
						color: #999;
						cursor: pointer;
					}
				}
			}
		}
		
		.select-item1{
			display: flex;
			justify-content: space-between;
			margin-right: 20px;
		}

		.upload {
			margin-top: 20px;
			display: flex;
			align-items: center;

			.file-name {
				font-size: 12px;
				padding-left: 10px;
				padding-top: 3px;
			}
		}

		.content {
			max-height: 500px;
			overflow: auto;
		}

		.select-employee {
			margin-top: 20px;

			.select-tip {
				margin-left: 70px;
				font-size: 12px;
				color: #999;
				line-height: 18px;
			}

			.employee-list {
				margin-left: 70px;
				margin-top: 15px;
				display: flex;
				flex-wrap: wrap;
				// max-width: 90%;
				// border:1px solid black;

				.employee-item {
					display: flex;
					align-items: center;
					height: 26px;
					margin-right: 10px;
					margin-bottom: 5px;
					padding: 0 5px;
					font-size: 12px;
					border: 1px solid #ddd;
					border-radius: 3px;

					.e-hd {
						width: 18px;
						height: 18px;
						overflow: hidden;
						border-radius: 2px;
						margin-right: 5px;
						background-color: #F6F6F6;
					}
					.e-no-hd{
						display: inline-block;
						width: 18px;
						height: 18px;
						line-height: 18px;
						text-align: center;
						font-size: 12px;
						color: #fff;
						border-radius: 2px;
						vertical-align: 1px;
						background-color: #409eff;
						margin-right: 5px;
					}

					.e-delete{
						margin-left: 5px;
						font-size: 14px;
						cursor: pointer;
					}
				}
			}
		}

		.importcalss .el-dialog__header {
			display: none;
		}

		.el-dialog__body {
			padding-top: 20px;
		}
		
		.normal-dialog .el-dialog__header {
			display: block;
		}
	}
.positiontree .el-tree-node__content{
	.el-checkbox{
		position: absolute;
		right: 0px;
	}
}
</style>
<style lang = "less" scoped>
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.grayfont{
		font-size: 12px;
		line-height: 1.5;
		color: #999999;
		max-width: 80%;
	}
	.e-hd {
		width: 18px;
		height: 18px;
		overflow: hidden;
		border-radius: 2px;
		margin-right: 5px;
		background-color: #F6F6F6;
	}
	.e-no-hd{
		display: inline-block;
		width: 18px;
		height: 18px;
		line-height: 18px;
		text-align: center;
		font-size: 12px;
		color: #fff;
		border-radius: 2px;
		vertical-align: 1px;
		background-color: #409eff;
		margin-right: 5px;
	}
	.formlabel ::v-deep .el-form-item__label{
		font-weight: 100;
	}
</style>
<style lang="less">
</style>
