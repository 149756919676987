<template>
  <div v-loading = "pageloading">
    <el-form :model="ruform" :rules="rules" ref="ruform" label-width="150px" style="margin-bottom:100px">
      <el-card>
        <div class = "cardtitle">帐号设置</div>
        <div>
          <div class = "bluebox" style="margin:0px 0px 20px 0px">
            <span>温馨提示：上传的帐号头像和名称，将会在对话界面，展示给客户哦~</span>
            <el-popover placement="bottom" trigger="hover">
              <img :src="imgurl+'/image/serviceaccounteditimg.png'" style="width:200px" alt="">
              <el-button  slot="reference" type="text" style="margin:0px 0px 0px 10px;padding:0px">查看示例</el-button>
            </el-popover>
          </div>
          <el-form-item label = "帐号头像：" class = "formtext" prop="HeadUrl">
            <div class = "flexRow" style="align-items:stretch">
              <img :src="imgurl+ruform.HeadUrl" style="width:120px;height:120px;border-radius:3px" alt="">
              <div class = "flexCol" style="margin-left:20px;justify-content: space-between;">
                <div>
                  <div class = "grayfont">图片上传建议：</div>
                  <div class = "grayfont">1.图片宽高尺寸比例为1:1</div>
                  <div class = "grayfont">2.请上传jpg、png格式的图片</div>
                  <div class = "grayfont">3.图片大小不超过2M</div>
                </div>
                <el-upload :action="imgapi" class="avatar-uploader" :show-file-list = "false"
                  :on-success="handleAvatarSuccess" accept="image/*"
                  :before-upload="beforeAvatarUpload">
                  <el-button type="text" style="margin:0px;padding:0px">修改</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="帐号名称：" prop="Name">
            <el-input v-model="ruform.Name" placeholder="请输入帐号名称，最多16个字" style="width:400px" maxlength="16"></el-input>
          </el-form-item>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "cardtitle">接待人员设置</div>
        <div style="width:70%">
          <el-form-item label="接待人员设置：" prop="MallEmployeeIdList">
            <el-button type="text" @click="staffshow=true,getmessagelist()">选择员工</el-button>
            <div class = "grayfont">接待人员将以客服帐号向客户提供服务（对话界面，不会显示接待人员的姓名），最多可配置500人</div>
            <div>
              <div class = 'flexRow' style="flex-wrap:wrap;margin-top:10px">
                <div class="employee-item" v-for="(item, index) in stafflist" :key="index">
                  <div class = 'flexRow'>
                    <img class="e-hd" :src="item.HeadUrl?item.HeadUrl:(imgurl+'/image/yuaserviceaccounteditn.png')" />
                    <!-- <span class="e-no-hd" v-if="item.Type===3 && !item.HeadUrl">员</span> -->
                    <!-- <i class="el-icon-s-shop e-shop" v-if="item.Type!==3" style="color: #409eff;font-size: 20px;vertical-align: -2px;"></i> -->
                    {{item.Name}}
                    <i class="el-icon-close e-delete" @click="handleDeleteEmployee(index)"></i>
                  </div>
                </div>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "cardtitle">接待规则设置</div>
        <div>
          <el-form-item label="客服欢迎语：" :class="{havestar:ruform.WelcomeType==2}">
            <div>
              <el-radio-group v-model="ruform.WelcomeType">
                <el-radio :label="1">发送文本消息</el-radio>
                <el-radio :label="2">发送菜单消息</el-radio>
              </el-radio-group>
            </div>
            <div class = "grayfont">
              客户进入会话时将会收到该欢迎语。每48小时最多收到一条
            </div>
            <div style="margin-top:10px">
              <div v-if="ruform.WelcomeType==1">
                <el-input type="textarea" v-model="ruform.WelcomeContent" :rows="5" style="width:500px;" show-word-limit maxlength="200"
                placeholder="请输入文本消息，若不填，将使用系统默认文案：您好，很高兴为您服务，请问有什么可以帮到您？"></el-input>
              </div>
              <div v-else-if="ruform.WelcomeType==2">
                <el-form-item label-width="0" prop="WelcomeQyWeixinKfMenuMessageId">
                  <el-select v-model="ruform.WelcomeQyWeixinKfMenuMessageId" style="width:400px">
                    <el-option v-for="(v,i) in WelcomeQyWeixinKfMenuMessageIdlist" :key="i" :value="v.Id" :label="v.Name"></el-option>
                    <div slot="empty" class="flexRow" style="justify-content:center;padding:5px 0px">
                      <span style="color:#606266;font-size:14px">还未添加菜单消息，</span>
                      <el-button type="text" @click="jump(1)">前往添加</el-button>
                    </div>
                  </el-select>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="会话分配规则：">
            <el-radio-group v-model="ruform.RuleType">
              <el-radio :label="0">轮流分配</el-radio>
              <el-radio :label="1">空闲分配</el-radio>
            </el-radio-group>
            <div class = "grayfont" v-if="ruform.RuleType==0">新咨询消息轮流分配给所有接待人员</div>
            <div class = "grayfont" v-if="ruform.RuleType==1">新咨询消息分配给当前接待人数最少的接待人员</div>
          </el-form-item>
          <el-form-item label="优先分配：">
            <el-checkbox v-model="ruform.IsPriorityDistributePreKf">优先分配给上次接待人员</el-checkbox>
          </el-form-item>
          <el-form-item label="接待上限：" prop="ReceptionCeiling" class = "havestar errRight">
            <div class = "flexRow">
              <span>一个接待人员同时最多接待</span>
              <el-input v-model="ruform.ReceptionCeiling" style="margin:0px 10px;width:150px"></el-input>
              <span>人咨询</span>
            </div>
          </el-form-item>
          <el-form-item label="排队提示语：" class="formtext">
            <div style="margin-top:7px">
              <div class = "grayfont">
                当所有接待人员都达到了接待上限，新的客户咨询将进入排队池，此时系统自动发送排队提示语
              </div>
              <div>
                <!-- <el-button type="text" @click="jump(2)" style="font-size:12px">如何设置接待员的接待上限值？</el-button> -->
              </div>
              <div style="margin-top:10px">
                <el-input type="textarea" v-model="ruform.QueuePromptContent" :rows="5" style="width:500px;" show-word-limit maxlength="200"
                  placeholder="请输入提示语，若不填，将使用系统默认文案：当前咨询人数过多，您可以先描述问题，我们正以最快的速度为您调配人工客服，感谢您的耐心等待~"></el-input>
              </div>
            </div>
          </el-form-item>
          <el-form-item label="接待时间：">
            <el-radio-group v-model="ruform.ReceptionTimeType">
              <el-radio :label="0">全天</el-radio>
              <el-radio :label="1">设置接待时间</el-radio>
            </el-radio-group>
            <el-form-item label-width="0px" prop="ReceptionEndTime" class="errRight-Time" v-if="ruform.ReceptionTimeType==1">
              <div class = "flexRow">
                <span>上班</span>
                <el-time-picker v-model="ruform.ReceptionBeginTime" style="width:150px;margin:0px 10px" value-format="HH:mm"
                 format="HH:mm" placeholder="任意时间点" :clearable="false" />
                <span>~下班</span>
                <el-time-picker v-model="ruform.ReceptionEndTime" style="width:150px;margin:0px 10px" value-format="HH:mm"
                 format="HH:mm" placeholder="任意时间点" :clearable="false" />
              </div>
            </el-form-item>
          </el-form-item>
          <el-form-item label="非接待时间提示语：" class="formtext" v-if="ruform.ReceptionTimeType==1">
            <div style="margin-top:10px">
              <el-input type="textarea" v-model="ruform.NonReceptionTimePromptContent" :rows="5" style="width:500px;" show-word-limit maxlength="200"
                placeholder="请输入提示语，若不填，将使用系统默认文案：您好，人工客服当前不在线。您可以先留言，客服上班后会第一时间回复您。"></el-input>
            </div>
          </el-form-item>
        </div>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "cardtitle">结束会话设置</div>
        <el-form-item label="超时结束会话：">
          <div class = "flexRow">
            <span>接入人工服务后，客户超过</span>
            <el-select v-model="ruform.TimeoutEndMinute" style="margin:0px 10px;width:150px">
              <el-option v-for="v in timeoutList" :key="v.value" :value="v.value" :label="v.label"></el-option>
            </el-select>
            <span>未发送消息，系统自动结束会话</span>
          </div>
        </el-form-item>
        <el-form-item label="结束语：" class="formtext">
          <div style="margin-top:10px">
            <el-radio-group v-model="ruform.IsAutoSendEvaluate">
              <el-radio :label="false">发送普通消息</el-radio>
              <el-radio :label="true">发送满意度评价</el-radio>
            </el-radio-group>
            <div style="margin-top:7px" v-if="!ruform.IsAutoSendEvaluate">
              <div class = "grayfont">
                接待人员主动结束会话，此时客户将会收到该结束语
              </div>
              <div style="margin-top:10px">
                <el-input type="textarea" v-model="ruform.EndContent" :rows="5" style="width:500px;" show-word-limit maxlength="200"
                  placeholder="请输入结束语，若不填，将使用系统默认文案：感谢您的支持与信任，本次会话已结束，若您后续有其他疑问，欢迎随时联系我们。"></el-input>
              </div>
            </div>
            <div class = "grayfont" v-else>
              选中该项，当接待人员主动结束会话，系统将发送满意度评价
              <el-popover placement="bottom" trigger="hover">
                <img :src="imgurl+'/image/serviceaccount-satisfaction.png'" style="width:200px" alt="" />
                <el-button slot="reference" type="text">查看示例</el-button>
              </el-popover>
            </div>
          </div>
        </el-form-item>
      </el-card>
      <el-card style="margin-top:10px">
        <div class = "cardtitle">更多设置</div>
        <el-form-item label="智能回复：">
          <el-checkbox v-model="ruform.IsOpenIntelligentReply">开启智能回复</el-checkbox>
          <div class = "grayfont">
            开启后，客户咨询时将先根据录入的知识库自动回复客户，无法回复时再转到人工服务
            <el-button type="text" @click="$router.push({path:'/customerServe/WxCustomerServe/smartReply/smartreply'})">前往管理知识库</el-button>
          </div>
        </el-form-item>
        <el-form-item label="超时未回复提醒：">
          <el-checkbox v-model="ruform.IsOpenTimeoutRemind">开启超时未回复提醒</el-checkbox>
          <div class = "grayfont">
            开启后，若接待人员未在规定时间内回复客户咨询的消息，系统自动给接待人员推送提醒
            <el-popover placement="bottom" trigger="hover">
              <img :src="imgurl+'/image/serviceaccount-overtime.png'" style="width:200px" alt="" />
              <el-button slot="reference" type="text">查看示例</el-button>
            </el-popover>
          </div>
          <el-form-item label-width="0px" prop="TimeoutRemindMinute" v-if="ruform.IsOpenTimeoutRemind" class = "errRight-TimeoutRemindMinute">
            <div class="flexRow">
              <span>接待人员超过</span>
              <el-input style="width:150px;margin:0px 10px" v-model="ruform.TimeoutRemindMinute"></el-input>
              <span>分钟未回复咨询消息时，给接待人员推送提醒</span>
            </div>
          </el-form-item>
        </el-form-item>
      </el-card>

    </el-form>
    <el-card class="bottombox">
      <el-button @click="cancal">取消</el-button>
      <el-button type="primary" @click="save('ruform')" :loading="btnLoading">保存</el-button>
    </el-card>

    <choosestaff :visible = "staffshow" v-if="staffshow" @closestaff="closestaff" @confirm="confirm" :sellist="stafflist"></choosestaff>
  </div>
</template>

<script>
import {
  qyWeixinKfAccountedit,
  qyWeixinKfAccountinfo,
  qyWeixinKfMenuMessagelist
}from "@/api/sv3.0.0"
import config from "@/config"
import choosestaff from "../../component/choosestaff"
export default {
  components: {
    choosestaff
  },
  data () {
    var checkPeopleNum = (rule, value, callback) => {
      if(value < 1 || value > 50 || value != parseInt(value)){
        callback(new Error('人数请设置在1人~50人之间，整数'));
      }else{
        callback()
      }
    };
    var checkReceptionEndTime = (rule, value, callback) => {
      let startTime = new Date('2000-1-1 ' + this.ruform.ReceptionBeginTime + ':00').getTime()
      let endTime = new Date('2000-1-1 ' + this.ruform.ReceptionEndTime + ':00').getTime()
      // console.log(startTime>=endTime)
      
      if(!this.ruform.ReceptionBeginTime&&!this.ruform.ReceptionEndTime){
        callback(new Error('请设置上下班时间'))
      }else if(!this.ruform.ReceptionBeginTime||!this.ruform.ReceptionEndTime){
        callback(new Error('下班时间不可早于上班时间'))
      }else if(startTime>=endTime){
        callback(new Error('下班时间不可早于上班时间'))
      }else{
        callback()
      }
    };
    var checkTimeoutRemind = (rule, value, callback) => {
      // console.log(value)
      if(value < 1 || value > 60 || value != parseInt(value)){
        callback(new Error('时间请设置在1分钟~60分钟之间，整数'));
      }else if(value >= this.ruform.TimeoutEndMinute){
        callback(new Error('提醒时间不可晚于自动结束会话时间'));
      }else{
        callback()
      }
    };
    return {
      pageloading:false,
      imgapi:config.UPLOAD_IMG,
      imgurl:config.IMG_BASE,
      ruform:{
        Id:0,
        HeadUrl:'/image/20220113-5742e41c-a917-4013-9e74-eca924d25b2d.png',
        Name:'',
        MallEmployeeIdList:[],
        WelcomeType:1,
        WelcomeContent:'您好，很高兴为您服务，请问有什么可以帮到您？',
        WelcomeQyWeixinKfMenuMessageId:null,
        QueuePromptContent:'当前咨询人数过多，您可以先描述问题，我们正以最快的速度为您调配人工客服，感谢您的耐心等待~',
        EndContent:'感谢您的支持与信任，本次会话已结束，若您后续有其他疑问，欢迎随时联系我们。',
        RuleType:0,
        IsPriorityDistributePreKf:false,
        ReceptionCeiling:20,
        ReceptionTimeType:0,
        ReceptionBeginTime:'9:00',
        ReceptionEndTime:'21:00',
        NonReceptionTimePromptContent:'您好，人工客服当前不在线。您可以先留言，客服上班后会第一时间回复您。',
        TimeoutEndMinute:2880,
        IsAutoSendEvaluate:false,
        IsOpenIntelligentReply:false,
        IsOpenTimeoutRemind:false,
        TimeoutRemindMinute:5,
      },
      rules:{
        HeadUrl:[
          { required: true, message: '请设置客服帐号头像', trigger: 'blur' },
        ],
        Name:[
          { required: true, message: '请设置客服帐号名称', trigger: 'blur' },
        ],
        MallEmployeeIdList:[
          { required: true, message: '请选择接待人员', trigger: 'blur' },
        ],
        WelcomeQyWeixinKfMenuMessageId:[
          { required: true, message: '请选择发送的菜单消息', trigger: 'blur' },
        ],
        ReceptionCeiling:[
          { validator: checkPeopleNum, trigger: 'blur' },
        ],
        ReceptionEndTime:[
          { validator: checkReceptionEndTime, trigger: 'blur' },
        ],
        TimeoutRemindMinute:[
          { validator: checkTimeoutRemind, trigger: 'blur' },
        ],
      },
      staffshow:false,
      WelcomeQyWeixinKfMenuMessageIdlist:[],
      stafflist:[],
      timeoutList:[
        {value:5,label:'5分钟'},
        {value:10,label:'10分钟'},
        {value:20,label:'20分钟'},
        {value:30,label:'30分钟'},
        {value:60,label:'1小时'},
        {value:720,label:'12小时'},
        {value:1440,label:'24小时'},
        {value:2880,label:'48小时'},
      ],
      btnLoading:false,
    }
  },
  created () {
    
    // console.log(new Date('2022-11-11 9:00').getTime())
    if(Number(this.$route.query.Id)){
      this.info()
    }
    this.getmessagelist()
  },
  methods: {
    async getmessagelist(){
      try{
        let data = {
          Skip:0,
          Take:999999,
        }
        let res = await qyWeixinKfMenuMessagelist(data)
        if(res.IsSuccess){
          this.WelcomeQyWeixinKfMenuMessageIdlist = res.Result.Results
          // console.log(this.WelcomeQyWeixinKfMenuMessageIdlist)
        }
      }finally{
        //
      }
    },
    async info(){
      try{
        this.pageloading = true
        let data = {
          Id:this.$route.query.Id
        }
        let res = await qyWeixinKfAccountinfo(data)
        if(res.IsSuccess){
          let ruform = res.Result
          if(!ruform.TimeoutRemindMinute){
            ruform.TimeoutRemindMinute = 5
          }
          if(ruform.ReceptionTimeType==0){
            ruform.ReceptionBeginTime = '9:00'
            ruform.ReceptionEndTime = '21:00'
          }
          this.stafflist = JSON.parse(JSON.stringify(ruform.QyWeixinKfEmployeeInfoList.map((v)=>{
            v.Name = v.EmployeeName
            v.Id = v.MallEmployeeId
            v.HeadUrl = v.WxHeadUrl
            v.Phone = v.EmployeePhone
            v.Type = 3
            return v
          })))
          ruform.MallEmployeeIdList = this.stafflist.map((v)=>{
            return v.Id
          })
          ruform.WelcomeQyWeixinKfMenuMessageId = ruform.WelcomeQyWeixinKfMenuMessageId == 0?null:ruform.WelcomeQyWeixinKfMenuMessageId
          this.ruform = ruform
        }
      }finally{
        this.pageloading = false
      }
    },
    jump(e){
      if(e==1){
        this.$router.push({
          path:'/customerServe/WxCustomerServe/menumessage'
        })
      }else if(e==2){
        window.open('https://jusnn6k8al.feishu.cn/docs/doccnjA9zgoygbA9ONqXKQLx8eg')
      }else if(e==3){
        window.open('https://jusnn6k8al.feishu.cn/docs/doccnjvdjhlfzFvnbfT7SrtJPle')
      }
    },
    handleDeleteEmployee(e){
      this.ruform.MallEmployeeIdList.splice(e,1)
      this.stafflist.splice(e,1)
    },
    confirm(list){
      // console.log(list)
      this.stafflist = list
      this.ruform.MallEmployeeIdList = list.map((v)=>{
        return v.Id
      })
      this.staffshow = false
    },
    closestaff(){
      this.staffshow = false
    },
    handleAvatarSuccess(file){
      // console.log(file)
      this.ruform.HeadUrl = file[0]
    },
    beforeAvatarUpload(file){
      // console.log(file)
      const size = file.size / 1024 / 1024 < 2;
      const testtype = ['image/bmp', 'image/png', 'image/tif', 'image/gif', 'image/jpeg', 'image/jpg',
        'image/webp'
      ]
      if (testtype.indexOf(file.type) == -1) {
        this.$message.error('请选择图片上传');
        return false
      } else if (!size) {
        this.$message.error('头像图片请控制在2M以内');
        return false
      }
    },
    save(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(123)
          this.tosave()
        } else {
          this.$message.error('请完善配置项')
          this.$nextTick(()=>{
            let iserror = document.getElementsByClassName('is-error')
            iserror[0].scrollIntoView({
              block:'center',
              behavior:'smooth'
            })
          })
          return false;
        }
      });
    },
    async tosave(){
      try{
        this.btnLoading = true
        this.pageloading = true
        let data = JSON.parse(JSON.stringify(this.ruform))
        console.log(data)
        if(!data.WelcomeContent){
          data.WelcomeContent = '您好，很高兴为您服务，请问有什么可以帮到您？'
        }
        if(!data.QueuePromptContent){
          data.QueuePromptContent = '当前咨询人数过多，您可以先描述问题，我们正以最快的速度为您调配人工客服，感谢您的耐心等待~'
        }
        if(!data.EndContent){
          data.EndContent = '感谢您的支持与信任，本次会话已结束，若您后续有其他疑问，欢迎随时联系我们。'
        }
        if(!data.NonReceptionTimePromptContent){
          data.NonReceptionTimePromptContent = '您好，人工客服当前不在线。您可以先留言，客服上班后会第一时间回复您。'
        }
        if(data.IsAutoSendEvaluate){
          data.EndContent = '感谢您的支持与信任，本次会话已结束，若您后续有其他疑问，欢迎随时联系我们。'
        }
        let res = await qyWeixinKfAccountedit(data)
        console.log(res)
        if(res.IsSuccess){
          this.$message.success('保存成功')
          this.cancal()
        }
      }finally{
        this.pageloading = false
        this.btnLoading = false
      }
    },
    cancal(){
      this.$router.go(-1)
    },
  }
}
</script>

<style lang = "less" scoped>
.cardtitle{
  font-size: 16px;
  /* font-weight: bold; */
  color: #303133;
  margin-bottom: 20px;
}
.bluebox{
  padding: 7px 10px;
  background: #FBFDFF;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #409EEF;
  font-size: 14px;
  color: #666666;
  line-height: 1.5;
}
.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.flexCol{
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.grayfont{
  font-size: 12px;
  color: #999999;
  line-height: 1.5;
}
.formtext ::v-deep .el-form-item__content{
  line-height: 1;
} 
.employee-item {
  display: flex;
  align-items: center;
  height: 36px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 0 5px;
  font-size: 12px;
  border: 1px solid #ddd;
  border-radius: 3px;

  .e-hd {
    width: 20px;
    height: 20px;
    overflow: hidden;
    border-radius: 2px;
    margin-right: 5px;
    background-color: #F6F6F6;
  }
  .e-no-hd{
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-radius: 2px;
    vertical-align: 1px;
    background-color: #409eff;
    margin-right: 5px;
  }

  .e-delete{
    margin-left: 5px;
    font-size: 14px;
    cursor: pointer;
  }
}
.bottombox{
  position: fixed;
  bottom: 0px;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  justify-content: center;
  margin-left: -8%;
  z-index: 999;
}
.havestar ::v-deep .el-form-item__label::before{
  content: '*';
  color: #f56c6c;
  margin-right: 4px;
}
.errRight ::v-deep .el-form-item__error{
  left: 180px;
}
.errRight-Time ::v-deep .el-form-item__error{
  left: 37px;
}
.errRight-TimeoutRemindMinute ::v-deep .el-form-item__error{
  left: 95px;
}
</style>